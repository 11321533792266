import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppService } from '../app.service';

@Component({
  selector: 'app-simulasi-kredit',
  templateUrl: './simulasi-kredit.component.html',
  styleUrls: ['./simulasi-kredit.component.scss']
})
export class SimulasiKreditComponent implements OnInit {
  url!: string;

  constructor(private titleService: Title, private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setIsBackButton(true);
    this.appService.setIsTitle('Beranda / ');
    this.appService.setIsTitlePath('Simulasi Kredit');
    this.appService.setIsLogo(false);
    this.appService.setIsColor('#181A1E');

    this.url = "https://api.digimaxoto.com/instalment/cicilan_sim.php";
  }

}
