<div class="container">
    <div style="padding-top: 60px;">
        <div style="padding-left: 20px;padding-right: 20px;">
            <div style="padding: 10px;" class="row">
                <mat-label style="font-family: Roboto;color: white;font-size: smaller;padding-left: 0px;">Nomor Pemesanan</mat-label>
                <div class="col-6" style="padding-top: 10px;padding-left: 0px;">
                    <input type="text" class="form-control" style="background-color: #181A1E;border-color: #181A1E;color: #A6B0B9;font-family: Roboto;" placeholder="Masuka No.Transaksi" aria-label="Masuka No.Transaksi">
                </div>
            </div>
        </div>
    </div>
</div>