import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
  actualImage: string;
  changeBackgroundCounter = 0;
  slide = [
    {image: '../../assets/volta-image-1.png'},
    {image: '../../assets/volta-image-2.png'},
    {image: '../../assets/volta-image-3.png'}
  ]

  constructor() { 
    this.actualImage = this.slide[0].image;
    setInterval(() => {
      this.changeBackgroundCounter++;
      if (this.changeBackgroundCounter > this.slide.length - 1) {
        this.changeBackgroundCounter = 0;
      }
      this.actualImage = this.slide[this.changeBackgroundCounter].image;
    }, 3000);
  }

  ngOnInit(): void {
  }

}
