<mat-label style="font-weight: bold;color: white;">Daftar Produk</mat-label>
<mat-grid-list *ngIf="isLoading; else showLoading" cols="2" rowHeight="16rem" style="color: white;margin-top: 20px;margin-left: 10px;margin-right: 10px;">
    <mat-grid-tile *ngFor="let product of resultsProduct.data; let indexOfelement = index;">
        <button mat-flat-button (click)="toDetail(resultsProduct.data[indexOfelement])" style="background-color: #181A1E;margin: 10px;">
            <img src="{{product.data_image[0].images[0]}}" class="img-fluid" style="width: 150px;height: 120px;padding-top: 10px;" alt="volta">
            <mat-label style="color: white;display: flex;">{{product.name}}</mat-label>
            <p style="color: white;font-family: Roboto;font-size: x-small;line-height: 1em;display: flex;justify-content: start;">{{product.type}}</p>
            <div style="margin-bottom: 15px;">
                <button mat-flat-button (click)="toDetail(resultsProduct.data[indexOfelement])" style="background-color: #32B44A;font-family: Roboto;font-size: smaller;width: 100%;color: black;">Lihat Detail</button>
            </div>
        </button>
    </mat-grid-tile>
</mat-grid-list>
<ng-template #showLoading>
    <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status"></div>
    </div>
 </ng-template>