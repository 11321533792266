import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { ProductModel } from './model/product-model';

@Injectable()
export class AppService {
  redirectUrl: any;
  private historyNavigation: String[] = [];

  private isBackButton = new BehaviorSubject<boolean>(false);
  private isBackButton$ = this.isBackButton.asObservable();

  private isLogo = new BehaviorSubject<boolean>(false);
  private isLogo$ = this.isLogo.asObservable();

  private isTitle = new BehaviorSubject<String>('DigimaxOTO');
  private isTitle$ = this.isTitle.asObservable();

  private isTitlePath = new BehaviorSubject<String>('DigimaxOTO');
  private isTitlePath$ = this.isTitlePath.asObservable();

  private isColor = new BehaviorSubject<String>('black');
  private isColor$ = this.isColor.asObservable();

  sharedDataProduct!: ProductModel.Data;
  private detailSourceProduct = new BehaviorSubject<ProductModel.Data>(this.sharedDataProduct);
  currentDetailProduct = this.detailSourceProduct.asObservable();

  constructor(private router: Router, private location: Location) {}

  setIsBackButton(isBackButton: boolean) {
    this.isBackButton.next(isBackButton);
  }

  getIsBackButton(): Observable<boolean> {
    return this.isBackButton$;
  }

  setIsLogo(isLogo: boolean) {
    this.isLogo.next(isLogo);
  }

  getIsLogo(): Observable<boolean> {
    return this.isLogo$;
  }

  setIsTitle(isTitle: String) {
    this.isTitle.next(isTitle);
  }

  getIsTitle(): Observable<String> {
    return this.isTitle$;
  }

  setIsTitlePath(isTitlePath: String) {
    this.isTitlePath.next(isTitlePath);
  }

  getIsTitlePath(): Observable<String> {
    return this.isTitlePath$;
  }

  setIsColor(isColor: String) {
    this.isColor.next(isColor);
  }

  getIsColor(): Observable<String> {
    return this.isColor$;
  }

  changeDetail(sharedDataProduct: ProductModel.Data) {
    this.detailSourceProduct.next(sharedDataProduct);
  }

  goBack(): void {
    // this.historyNavigation.pop();

    // if (this.historyNavigation.length > 0) {
      this.location.back();
    // } else {
    //   this.router.navigateByUrl("/");
    // }
  }

  getPreviousUrl(): String {
    if (this.historyNavigation.length > 0) {
      return this.historyNavigation[this.historyNavigation.length - 2];
    }

    return '';
  }
}