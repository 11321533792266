import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { ProductModel } from '../model/product-model';
import { ProductService } from '../service/product.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  resultsProduct!: ProductModel.RootObject;
  resultsProductDetail!: ProductModel.Data;
  subscription!: Subscription;
  getUrl!: string;
  isLoading: boolean = false;

  constructor(private router: Router, private appService: AppService, private apiProduct: ProductService,
    activedRoute: ActivatedRoute) { 
    this.subscription = this.appService.currentDetailProduct.subscribe(
      (message) => (this.resultsProductDetail = message)
    );
    // this.getUrl = 'eyAicGlkIjogIk43Slg5VlREIiwgIm1pZCI6ICJMRE4xMjNUU1Q0NTYiLCAibW5hbWUiOiAiRHJvcHBlciBQb2ludCBLYXNhYmxhbmthIiwgIm1jb250YWN0IjogIkFuZGkiLCAibXBob25lIjogIjYyODEyMzQ1Njc4OTAiLCAiYXV0aCI6ICJmN2FlNDZkY2I0MzMyNWJkMmVmZDc5ZDY2Mjc4NjhjMiIgfQ';
    this.getUrl = activedRoute.snapshot.params['id'];
  }

  ngOnInit(): void {
    this.getProducts();
  }

  getProducts() {
    this.isLoading = false;
    this.apiProduct.getProducts()
    .subscribe({
      next: (v) => {
        this.isLoading = true;
        this.resultsProduct = v;
      },
      error: (e) => {
        this.isLoading = true;
        console.error(e);
      },
      complete: () => {
        this.isLoading = true;
      }
    });
  }

  toDetail(productDetail: ProductModel.Data) {
    this.appService.changeDetail(productDetail);
    this.router.navigate(['/detail', this.getUrl]);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
