import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppService } from '../app.service';
import { AuthModel } from '../model/auth-model';
import { ProductModel } from '../model/product-model';
import { Provinces } from '../model/provinces-model';
import { ProductService } from '../service/product.service';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {
  getData!: ProductModel.Data;
  getImage!: ProductModel.Image;
  subscription!: Subscription;
  indexColor: number = 0;
  indexImage: number = 0;
  authModel!: AuthModel;
  temp!: string;
  getUrl!: string;

  provinces: Provinces[] = [
    {id: 1, name: 'DKI Jakarta', otr: "12000000"},
    {id: 2, name: 'Bandung', otr: "11500000"},
    {id: 3, name: 'Medan', otr: "10000000"},
  ];

  constructor(private appService: AppService, private titleService: Title, private router: Router, 
    activedRoute: ActivatedRoute, private apiProduct: ProductService) {
    // this.getUrl = 'eyAicGlkIjogIk43Slg5VlREIiwgIm1pZCI6ICJMRE4xMjNUU1Q0NTYiLCAibW5hbWUiOiAiRHJvcHBlciBQb2ludCBLYXNhYmxhbmthIiwgIm1jb250YWN0IjogIkFuZGkiLCAibXBob25lIjogIjYyODEyMzQ1Njc4OTAiLCAiYXV0aCI6ICJmN2FlNDZkY2I0MzMyNWJkMmVmZDc5ZDY2Mjc4NjhjMiIgfQ';
    // this.temp = 'eyAicGlkIjogIk43Slg5VlREIiwgIm1pZCI6ICJMRE4xMjNUU1Q0NTYiLCAibW5hbWUiOiAiRHJvcHBlciBQb2ludCBLYXNhYmxhbmthIiwgIm1jb250YWN0IjogIkFuZGkiLCAibXBob25lIjogIjYyODEyMzQ1Njc4OTAiLCAiYXV0aCI6ICJmN2FlNDZkY2I0MzMyNWJkMmVmZDc5ZDY2Mjc4NjhjMiIgfQ';
    this.getUrl = activedRoute.snapshot.params['id'];
    this.temp = atob(activedRoute.snapshot.params['id']);
    this.authModel = JSON.parse(this.temp);
    this.checkPartner(this.authModel.pid);
  }

  ngOnInit(): void {
    this.appService.setIsBackButton(true);
    this.appService.setIsTitle('Beranda / ');
    this.appService.setIsTitlePath('Detail Produk');
    this.appService.setIsLogo(false);
    this.appService.setIsColor('#181A1E');

    this.loadData();
  }

  onChange(s: any) {
    console.log(s);
  }

  loadData() {
    this.subscription = this.appService.currentDetailProduct.subscribe(
      (data) => {
        console.log(data)
        if(data != undefined){
          this.getData = data;
          this.getImage = data.data_image[this.indexColor];
          const title = 'DigimaxOTO - ' + this.getData.name;
          this.titleService.setTitle(title);
        } else {
          this.router.navigate(['/', this.getUrl]);
        }
      }
    );
  }

  async checkPartner(pid: string) {
    (await this.apiProduct.checkPartner(pid))
    .subscribe({
      next: (v) => {
        if(v.data == null) {
          this.router.navigate(['/error'], { replaceUrl: true });
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {}
    });
  }

  goToFormPage(productDetail: ProductModel.Data) {
    this.appService.changeDetail(productDetail);
    this.router.navigate(['/form', this.getUrl, this.indexColor]);
  }

  goToEcomm(url: string) {
    window.open(url, '_blank');
  }

  selectColor(i: number) {
    this.indexColor = i;
    this.loadData();
  }

  selectProduct(i: number) {
    this.indexImage = i;
  }

  getProvinces() {
    
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
