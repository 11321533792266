import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient) { }

  public getProducts() {
    const httpHeaders = new HttpHeaders()
      .set('Content-Type', 'application/json');
    const params = new HttpParams();
    return this.httpClient.get<any>(`${this.baseUrl}product/data.php`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }

  public async checkPartner(pid: string) {
    const httpHeaders = new HttpHeaders();
      const params = new HttpParams()
      .set('pid', pid);
    return this.httpClient.get<any>(`${this.baseUrl}product/check_partner.php`, { headers: httpHeaders, params: params })
      .pipe(map(response => {
        return response;
      }));
  }
}
