<div class="container">
    <div *ngIf="downloadFlyer">
        <div style="font-family: Roboto;font-size: smaller;">
            <button (click)="closeDialog()" style="color: black;background-color: #32B44A;margin-right: 10px;" type="button" class="btn">X</button>
            <mat-label>{{dataFlyer.type.name}} - {{dataFlyer.name}}</mat-label>
        </div>
        <div style="text-align: center;padding-top: 20px;">
            <a href="{{dataFlyer.image}}" target="_blank" download><img src="{{dataFlyer.image}}" class="img-fluid" alt="brosur-volta" style="height: 300px;cursor: pointer;"></a>
        </div>
        <div style="padding-top: 20px;">
            <a class="btn" href="{{dataFlyer.image}}" style="color: black;background-color: #32B44A;width: 100%;" download="{{dataFlyer.image}}">Unduh Brosur</a>
        </div>
    </div>
</div>