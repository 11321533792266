import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent implements OnInit {

  constructor(private appService: AppService) { }

  ngOnInit(): void {
    this.appService.setIsBackButton(true);
    this.appService.setIsTitle('Beranda / ');
    this.appService.setIsTitlePath('Status Pemesanan');
    this.appService.setIsLogo(false);
    this.appService.setIsColor('#181A1E');
  }

}
