import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss']
})
export class NavHeaderComponent implements OnInit {
  @Input() isBackButton!: boolean;
  @Input() isTitle!: String;
  @Input() isTitlePath!: String;
  @Input() isLogo!: boolean;
  @Input() isColor!: String;

  constructor(public appService: AppService, private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.appService.getIsBackButton().subscribe(appBackButton => {
      this.isBackButton = appBackButton
    });
    this.appService.getIsTitle().subscribe(appTitle => this.isTitle = appTitle);
    this.appService.getIsTitlePath().subscribe(appTitlePath => this.isTitlePath = appTitlePath);
    this.appService.getIsLogo().subscribe(appLogo => this.isLogo = appLogo);
    this.appService.getIsColor().subscribe(appColor => this.isColor = appColor);
    this.cd.detectChanges();
  }

}
