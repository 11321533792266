import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { FirstPageComponent } from './first-page/first-page.component';
import { Error404Component } from './error404/error404.component';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { NavFooterComponent } from './nav-footer/nav-footer.component';
import { BannerComponent } from './banner/banner.component';
import { AngularMaterialModule } from './angular-material.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment.prod';
import { DetailComponent } from './detail/detail.component';
import { AppService } from './app.service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlyerComponent } from './flyer/flyer.component';
import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';
import { ProductComponent } from './product/product.component';
import { TrackingComponent } from './tracking/tracking.component';
import { DialogComponent } from './dialog/dialog.component';
import { FormPageComponent } from './form-page/form-page.component';
import { SafePipe } from './safe.pipe';
import { initializeApp } from 'firebase/app';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { SimulasiKreditComponent } from './simulasi-kredit/simulasi-kredit.component';
initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FirstPageComponent,
    Error404Component,
    NavHeaderComponent,
    NavFooterComponent,
    BannerComponent,
    DetailComponent,
    FlyerComponent,
    HowToBuyComponent,
    ProductComponent,
    TrackingComponent,
    DialogComponent,
    FormPageComponent,
    SafePipe,
    MessageDialogComponent,
    SimulasiKreditComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    NgbModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    NgxMatSelectSearchModule,
    HttpClientModule,
    ReactiveFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:20000'
    })
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [AppService],
  bootstrap: [AppComponent]
})
export class AppModule { }
