import { Component, OnInit } from '@angular/core';
import packageJson from '../../../package.json';

@Component({
  selector: 'app-nav-footer',
  templateUrl: './nav-footer.component.html',
  styleUrls: ['./nav-footer.component.scss']
})
export class NavFooterComponent implements OnInit {
  public version: string = packageJson.version;

  constructor() { }

  ngOnInit(): void {
  }

  goToEcomm(url: string) {
    window.open(url, '_blank');
  }

}
