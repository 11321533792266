import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AppService } from '../app.service';
import { ProductModel } from '../model/product-model';

@Component({
  selector: 'app-form-page',
  templateUrl: './form-page.component.html',
  styleUrls: ['./form-page.component.scss']
})
export class FormPageComponent implements OnInit, OnDestroy {
  getData!: ProductModel.Data;
  subscription!: Subscription;
  url!: string;
  temp!: string;
  colorId!: number;

  constructor(private titleService: Title, private appService: AppService, private router: Router, 
    activedRoute: ActivatedRoute) { 
      // this.temp = 'eyAicGlkIjogIk43Slg5VlREIiwgIm1pZCI6ICJMRE4xMjNUU1Q0NTYiLCAibW5hbWUiOiAiRHJvcHBlciBQb2ludCBLYXNhYmxhbmthIiwgIm1jb250YWN0IjogIkFuZGkiLCAibXBob25lIjogIjYyODEyMzQ1Njc4OTAiLCAiYXV0aCI6ICJmN2FlNDZkY2I0MzMyNWJkMmVmZDc5ZDY2Mjc4NjhjMiIgfQ';
      this.temp = activedRoute.snapshot.params['id'];
      this.colorId = activedRoute.snapshot.params['colorId'];
  }

  ngOnInit(): void {
    this.appService.setIsBackButton(true);
    this.appService.setIsTitle('Beranda / ');
    this.appService.setIsLogo(false);
    this.appService.setIsColor('#181A1E');

    this.loadData();
  }

  loadData() {
    this.subscription = this.appService.currentDetailProduct.subscribe(
      (data) => {
        if(data != undefined){
          this.getData = data;
          // console.info(this.getData);
          this.url = environment.urlForm+'?volta='+this.temp+'&idk=1&idmr='+this.getData.merk_id+'&idmd='+this.getData.model_id+'&idw='+this.getData.data_image[this.colorId].color_id+'&idtp=2&idwl=&ontr=Ya';
          this.appService.setIsTitlePath(this.getData.name + ' - ' + this.getData.type);
          const title = 'DigimaxOTO - ' + this.getData.name + ' - ' + this.getData.type;
          this.titleService.setTitle(title);
        } else {
          this.router.navigate(['/', this.temp]);
        }
      }
    );
  }

  resizeIframe(obj: any) {
    obj.style.height = obj.contentWindow.document.body.scrollHeight + 'px';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
