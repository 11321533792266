import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from '../app.service';
import { AuthModel } from '../model/auth-model';
import { ProductService } from '../service/product.service';
import { CheckSubsidiService } from '../service/check-subsidi.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isBackButton!: boolean;
  isTitle!: String;
  isLogo!: boolean;
  authModel!: AuthModel;
  temp!: string;
  angForm!: FormGroup;
  isLoading!: boolean;

  constructor(private fb: FormBuilder, private appService: AppService, private titleService: Title, 
    private activedRoute: ActivatedRoute, private apiProduct: ProductService, private apiSubsidi: CheckSubsidiService, 
    private router: Router, private dialog: MatDialog) { 
    const title = 'DigimaxOTO';
    this.titleService.setTitle(title);
    this.temp = atob(this.activedRoute.snapshot.params['id']);
    this.authModel = JSON.parse(this.temp);
    this.checkPartner(this.authModel.pid);

    this.angForm = this.fb.group({
      nonik: ['', Validators.required]
    });

    this.isLoading = true;
  }

  ngOnInit(): void {
    this.appService.setIsBackButton(false);
    this.appService.setIsTitle('DigimaxOTO');
    this.appService.setIsLogo(true);
  }

  async checkPartner(pid: string) {
    (await this.apiProduct.checkPartner(pid))
    .subscribe({
      next: (v) => {
        if(v.message != "Partner Verified") {
          this.router.navigate(['/error'], { replaceUrl: true });
        }
      },
      error: (e) => {
        console.error(e);
      },
      complete: () => {}
    });
  }

  checkSubsidi(angForm: any) {
    this.isLoading = false;
    this.apiSubsidi.checkSubsidi(angForm.value.nonik)
      .subscribe((v) => {
        console.log(v.message);
        this.isLoading = true;
        if(v.data != undefined) {
          if(v.data.found == 1)
            this.callDialog("Selamat Anda mendapatkan Subsidi Motor Listrik.");
        } else {
          this.callDialog(v.message);
        }
      })
  }

  goToSimulasiKreditPage() {
    this.router.navigate([this.activedRoute.snapshot.params['id']+'/simulasi-kredit']);
  }

  callDialog(msg: String) {
    this.dialog.open(MessageDialogComponent, {
      data: msg,
    });
  }

}
