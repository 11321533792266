import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { DetailComponent } from './detail/detail.component';
import { Error404Component } from './error404/error404.component';
import { FirstPageComponent } from './first-page/first-page.component';
import { FlyerComponent } from './flyer/flyer.component';
import { FormPageComponent } from './form-page/form-page.component';
import { HomeComponent } from './home/home.component';
import { HowToBuyComponent } from './how-to-buy/how-to-buy.component';
import { TrackingComponent } from './tracking/tracking.component';
import { SimulasiKreditComponent } from './simulasi-kredit/simulasi-kredit.component';

const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: ':id', component: HomeComponent},
  {
    path: '', component: FirstPageComponent, children: [
      {path: ':id', component: HomeComponent},
      {path: 'detail/:id', component: DetailComponent},
      {path: 'brosur', component: FlyerComponent},
      {path: 'how-to-buy', component: HowToBuyComponent},
      {path: 'tracking', component: TrackingComponent},
      {path: 'form/:id/:colorId', component: FormPageComponent},
      {path: ':id/simulasi-kredit', component: SimulasiKreditComponent},
      {path: '**', component: Error404Component},
    ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      anchorScrolling: 'enabled',
      scrollPositionRestoration: 'enabled'
    }),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
],
  exports: [RouterModule]
})
export class AppRoutingModule { }
