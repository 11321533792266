export const environment = {
  production: true,
  apiURL: 'https://api.digimaxoto.com/',
  urlIndex: 'https://digimaxoto.com/',
  urlForm: 'https://api.digimaxoto.com/order/order_product.php',
  firebase: {
    apiKey: "AIzaSyCk5tXJ8uJIO4ShYcnhP6sJ9FCRNubblcg",
    authDomain: "digimaxoto.firebaseapp.com",
    projectId: "digimaxoto",
    storageBucket: "digimaxoto.appspot.com",
    messagingSenderId: "613327940821",
    appId: "1:613327940821:web:0cf495bfd45df7712b865c",
    measurementId: "G-5PCPW3C55X"
  }
};
