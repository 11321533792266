import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';

@Component({
  selector: 'app-how-to-buy',
  templateUrl: './how-to-buy.component.html',
  styleUrls: ['./how-to-buy.component.scss']
})
export class HowToBuyComponent implements OnInit {
  wording!: String;

  constructor(private appService: AppService) { 
    this.wording = "<p>Cara membeli moda transportasi di Digimaxoto</p><ul><li>Buka website Digimaxoto.</li><li>Pilih moda transportasi yang dibutuhkan.</li><li>Lihat spesifikasinya dan pilih warnanya.</li><li>Isi form pemesanan.</li><li>Bayar Down Payment Rp. 2.000.000 untuk tanda jadi.</li><li>Pilih metode pembayaran.</li></ul><p><br/>Jika Anda membutuhkan informasi lebih lanjut mengenai produk, pembayaran atau apapun mengenai Digimaxoto. Anda bisa menghubungi customer care kami di (021) 111 222 33.</p>";
  }

  ngOnInit(): void {
    this.appService.setIsBackButton(true);
    this.appService.setIsTitle('Beranda / ');
    this.appService.setIsTitlePath('Cara Beli');
    this.appService.setIsLogo(false);
    this.appService.setIsColor('#181A1E');
  }

}
