<div class="container">
    <div style="padding-top: 60px;">
        <div style="display: flex;padding-left: 20px;padding-right: 20px;">
            <div style="padding: 10px;">
                <mat-label style="font-family: Roboto;color: white;font-size: smaller;">Daerah OTR</mat-label>
                <select class="form-select" #mySelect (change)='onChange(mySelect.value)' style="margin-top: 10px;width: auto;background-color: #181A1E;border-color: #181A1E;color: white;font-family: Roboto;">
                    <option selected value="99">Semua</option>
                    <option *ngFor="let province of provinces;" value="{{province.id}}">{{province.name}}</option>
                </select>
            </div>
            <div style="padding: 10px;">
                <mat-label style="font-family: Roboto;color: white;font-size: smaller;">Tipe Volta</mat-label>
                <select class="form-select" #mySelectType (change)='onChange(mySelectType.value)' style="margin-top: 10px;width: auto;background-color: #181A1E;border-color: #181A1E;color: white;font-family: Roboto;">
                    <option selected value="99">Semua</option>
                    <option *ngFor="let type of types;" value="{{type.id}}">{{type.name}}</option>
                </select>
            </div>
        </div>
        <div style="padding: 20px 20px 0px 20px;">
            <div class="row" style="text-align: center;">
                <div class="col-6 col-md-4" *ngFor="let product of resultsFlyer.data; let indexOfelement = index;">
                    <div class="column" style="background-color: #181A1E;border-radius: 10px;">
                        <img src="{{product.image}}" class="img-fluid" style="width: auto;height: 150px;padding-top: 10px;" alt="volta">
                        <mat-label style="color: white;display: flex;padding: 10px 10px 0px 10px;max-height: 58px;line-height: 58px;align-items: end;font-family: Roboto;">{{product.name}}</mat-label>
                        <p style="padding: 10px 10px 0px 10px;color: white;font-family: Roboto;font-size: x-small;line-height: 1em;display: flex;justify-content: start;">{{product.type.name}}</p>
                        <div style="margin-bottom: 15px;padding: 0px 10px 10px 10px;">
                            <button mat-flat-button (click)="viewFlyer(product)" style="background-color: #32B44A;font-family: Roboto;font-size: smaller;width: 100%;color: black;">Unduh Brosur</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>