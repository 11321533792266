<div class="container min-vh-100" style="background-color: black;">
    <app-nav-header [isBackButton]="isBackButton" [isTitle]="isTitle" [isLogo]="isLogo"></app-nav-header>
    <app-banner></app-banner>
    <div style="text-align: center;padding: 20px;">
        <mat-label style="color: white;font-size: xx-large;line-height: 1em;font-weight: bold;">
            {{'Motor listrik Indonesia' | uppercase}}
        </mat-label>
        <br>
        <div style="padding-left: 30px;padding-right: 30px;">
            <mat-label style="color: #A6B0B9;font-size: x-large;font-family: Roboto;">
                tangguh dan handal 
            </mat-label>
        </div>
    </div>
    <mat-divider style="border-top-color: gray;margin: 20px;"></mat-divider>
    <div style="text-align: center;">
        <app-product></app-product>
    </div>
    <mat-divider style="border-top-color: gray;margin: 20px;"></mat-divider>
    <div style="text-align: center;padding: 20px;">
        <mat-label style="color: white;font-size: x-large;line-height: 1em;font-weight: bold;">
            {{'Sepeda Motor listrik Volta' | uppercase}}
        </mat-label>
        <br>
        <div style="padding-left: 30px;padding-right: 30px;margin-top: 20px;">
            <mat-label style="color: #A6B0B9;font-size: smaller;font-family: Roboto;">
                Mulai dari Rp. 9.900.000
            </mat-label>
        </div>
    </div>
    <div style="margin: 0px 30px 20px 30px;">
        <form [formGroup]="angForm" (ngSubmit)="checkSubsidi(angForm)" autocomplete="off" style="display: flex;width: 100%;">
            <input matInput type="number" formControlName="nonik" pattern="\d*" maxlength="4" class="form-control" style="margin-right: 20px;background-color: #ffffff;border-color: #7f7f7f;color: black;font-family: Roboto;" placeholder="Masuka No. KTP" aria-label="Masuka No. KTP">
            <div style="text-align: center;display: flex;flex-direction: column;align-items: center;">
                <button *ngIf="isLoading; else showLoading" mat-stroked-button style="background-color: transparent;border-color: #32B44A;color: #32B44A;font-family: Roboto;">Cek Subsidi</button>
                <ng-template #showLoading>
                    <button mat-stroked-button disabled style="color: rgb(187, 187, 187);font-family: Roboto;">
                        <mat-progress-spinner color="accent" diameter="20" style="margin: 10px;display: inline-block;" mode="indeterminate"></mat-progress-spinner>
                    </button>
                </ng-template>
            </div>
        </form>
    </div>
    <div style="text-align: center;display: flex;flex-direction: column;align-items: center;padding-bottom: 40px;">
        <button mat-stroked-button (click)="goToSimulasiKreditPage()" style="background-color: transparent;border-color: #32B44A;color: #32B44A;font-family: Roboto;">Simulasi Kredit</button>
    </div>
    <!-- <div style="text-align: center;display: flex;flex-direction: column;align-items: center;padding-bottom: 40px;">
        <button mat-stroked-button routerLink="/brosur" style="background-color: transparent;border-color: #32B44A;color: #32B44A;font-family: Roboto;">Unduh Brosur</button>
        <mat-label routerLink="/how-to-buy" style="color: #32B44A;padding: 20px;cursor: pointer;font-family: Roboto;">
            <u>Cara Beli</u>
        </mat-label>
    </div> -->
    <!-- <div style="background-color: #181A1E;display: flex;justify-content: space-between;align-items: center;">
        <mat-label style="font-family: Roboto;color: #A6B0B9;font-size: small;padding-left: 20px;padding-right: 10px;">
            Sudah melakukan pemesanan dan ingin melihat status transaksi Anda? Klik tombol Lihat Transaksi untuk melihat.
        </mat-label>
        <div routerLink="/tracking" style="background-color: transparent;color: #32B44A;font-family: Roboto;margin: 10px 20px 10px 10px;border-radius: 8px;padding: 10px;width: 40%;text-align: center;border-style: solid;cursor: pointer;">Lihat Transaksi</div>
    </div> -->
</div>
<app-nav-footer></app-nav-footer>