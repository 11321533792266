import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { AppService } from '../app.service';
import { DialogComponent } from '../dialog/dialog.component';
import { FlyerModel } from '../model/flyer-model';
import { Provinces } from '../model/provinces-model';
import { TypesModel } from '../model/types-model';

@Component({
  selector: 'app-flyer',
  templateUrl: './flyer.component.html',
  styleUrls: ['./flyer.component.scss']
})
export class FlyerComponent implements OnInit {
  resultsFlyer!: FlyerModel.RootObject;

  provinces: Provinces[] = [
    {id: 1, name: 'DKI Jakarta', otr: "12000000"},
    {id: 2, name: 'Bandung', otr: "11500000"},
    {id: 3, name: 'Medan', otr: "10000000"},
  ];

  types: TypesModel[] = [
    {id: 1, name: 'Volta 401'},
    {id: 2, name: 'Volta Virgo'},
  ];

  constructor(private titleService: Title, private appService: AppService, private dialog: MatDialog) { }

  ngOnInit(): void {
    const title = 'DigimaxOTO - Brosur Motor Listrik Murah';
    this.titleService.setTitle(title);

    this.appService.setIsBackButton(true);
    this.appService.setIsTitle('Beranda / ');
    this.appService.setIsTitlePath('Unduh Brosur');
    this.appService.setIsLogo(false);
    this.appService.setIsColor('#181A1E');

    this.resultsFlyer = {
      "data": [
        {
          "image": "https://dmmrnd.id/volta/brosur.png",
          "name": "OTR Jakarta",
          "type": {
            "id": 1,
            "name": "Volta 401"
          },
          "provinces" : {
            "id": 1,
            "name": "DKI Jakarta",
            "otr": ""
          }
        },
        {
          "image": "https://dmmrnd.id/volta/brosur.png",
          "name": "OTR Kab. Bandung",
          "type": {
            "id": 2,
            "name": "Volta Virgo"
          },
          "provinces" : {
            "id": 2,
            "name": "Bandung",
            "otr": ""
          }
        },
        {
          "image": "https://dmmrnd.id/volta/brosur.png",
          "name": "OTR Kab. Bogor",
          "type": {
            "id": 2,
            "name": "Volta Virgo"
          },
          "provinces" : {
            "id": 3,
            "name": "Bogor",
            "otr": ""
          }
        },
        {
          "image": "https://dmmrnd.id/volta/brosur.png",
          "name": "OTR Kab. Bogor",
          "type": {
            "id": 2,
            "name": "Volta Virgo"
          },
          "provinces" : {
            "id": 3,
            "name": "Bogor",
            "otr": ""
          }
        },
        {
          "image": "https://dmmrnd.id/volta/brosur.png",
          "name": "OTR Kab. Bandung",
          "type": {
            "id": 2,
            "name": "Volta Virgo"
          },
          "provinces" : {
            "id": 2,
            "name": "Bandung",
            "otr": ""
          }
        },
        {
          "image": "https://dmmrnd.id/volta/brosur.png",
          "name": "OTR Kab. Bogor",
          "type": {
            "id": 2,
            "name": "Volta Virgo"
          },
          "provinces" : {
            "id": 3,
            "name": "Bogor",
            "otr": ""
          }
        },
        {
          "image": "https://dmmrnd.id/volta/brosur.png",
          "name": "OTR Kab. Bogor",
          "type": {
            "id": 2,
            "name": "Volta Virgo"
          },
          "provinces" : {
            "id": 3,
            "name": "Bogor",
            "otr": ""
          }
        },
      ],
      "message": "",
      "status": ""
    };
  }

  onChange(s: any) {
    console.log(s);
  }

  viewFlyer(data: FlyerModel.Data) {
    this.dialog.open(DialogComponent, {data: {downloadFlyer: 'yes', data: data}, disableClose: true});
  }

}
