import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent implements OnInit {
  message: string;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: string, @Optional() private router: Router, private dialogRef: MatDialogRef<MessageDialogComponent>) { 
    this.message = data;
  }

  ngOnInit(): void {
  }

}
