import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CheckSubsidiService {
  redirectUrl: any;
  baseUrl: string = environment.apiURL;

  constructor(private httpClient: HttpClient) { }

  public checkSubsidi(nonik: String) {
    const httpHeaders = new HttpHeaders()
      .set('Authorization', "Basic ZzVDc09jWDM6MnkxMDFHZFRONWt3aUJuRGhoZ3BnNUNzT2NYM2RUTVNzYnZjOWlibVZ6dU05OWIzcU1LdnFqR08=")
      .set('Content-Type', 'application/json');
    const data = JSON.stringify({
      nonik
    });
    return this.httpClient.post<any>(`${this.baseUrl}order/order_ceknik.php`, data, { headers: httpHeaders })
      .pipe(map(response => {
        return response;
      }));
  }
}
