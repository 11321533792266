<div class="container">
    <div style="padding-top: 40px;text-align: center;">
        <img src="{{getImage.images[indexImage]}}" class="img-fluid" style="width: 450px;height: 350px;padding: 10px;" alt="volta">
        <div fxLayout="row" class="horizontal-mat-list-item" style="color: white;margin-top: 20px;margin-left: 10px;overflow-x: scroll;white-space: nowrap;margin-right: 15px;height: auto;">
            <button mat-flat-button (click)="selectProduct(indexOfelement)" [ngStyle]="{'background-color': indexOfelement == indexImage ? '#181A1E' : 'transparent' }" style="margin: 10px;" *ngFor="let productImage of getImage.images; let indexOfelement = index;">
                <img src="{{productImage}}" class="img-fluid" style="width: 150px;height: 120px;padding: 10px;" alt="volta">
            </button>
        </div>
    </div>
    <div style="padding: 20px;">
        <mat-label style="color: white;">{{'Pilih Warna' | uppercase}}</mat-label>
        <div style="display: flex;margin-top: 10px;">
            <div *ngFor="let colorSelect of getData.data_image; let indexOfelement = index;" (click)="selectColor(indexOfelement);" mat-flat-button [ngStyle]="{'background-color': colorSelect.color, 'border-color': indexOfelement == indexColor ? 'white' : '' }" style="width: 30px;height: 30px;border-radius: 8px;
            border: solid;margin-right: 10px;cursor: pointer;"></div>
        </div>
    </div>
    <div style="margin-left: 20px;margin-right: 20px;">
        <mat-label style="color: white;display: flex;font-size: x-large;">{{getData.name | uppercase}}</mat-label>
        <p style="color: #A6B0B9;font-family: Roboto;font-size: smaller;line-height: 1em;display: flex;justify-content: start;">{{getData.type}}</p>
    </div>
    <!-- <div style="margin-left: 20px;margin-right: 20px;">
        <select class="form-select" #mySelect (change)='onChange(mySelect.value)' style="width: auto;background-color: black;color: white;font-family: Roboto;">
            <option selected value="">Pilih lokasi untuk melihat harga</option>
            <option *ngFor="let province of provinces;" value="{{province.otr}}">{{province.name}}</option>
        </select>
        <p style="font-weight: bold;font-size: x-large;color: white;padding-top: 20px;">{{(mySelect.value | currency:'Rp ':'symbol':'3.0')}}</p>
    </div> -->
    <div style="margin: 20px;">
        <div style="border-radius: 8px;background-color: #181A1E;display: flex;justify-content: space-between;align-items: center;">
            <mat-label style="font-family: Roboto;color: #A6B0B9;font-size: small;padding-left: 10px;padding-right: 10px;">
                Miliki moda transportasi ini hanya dengan membayar Down Payment 2 juta. Info lebih lanjut <u style="font-weight: bold;cursor: pointer;">Baca Disini</u>
            </mat-label>
            <!-- <div [ngStyle]="{'background-color': mySelect.value != '' ? '#32B44A' : '#606060', 'cursor': mySelect.value != '' ? 'pointer' : ''}" style="font-family: Roboto;margin: 10px;border-radius: 8px;padding: 10px;width: 40%;text-align: center;color: black;"> -->
            <div style="background-color: #32B44A;cursor: pointer;font-family: Roboto;margin: 10px;border-radius: 8px;padding: 10px;width: 40%;text-align: center;color: black;">
                <!-- <mat-label *ngIf="mySelect.value != ''" (click)="goToFormPage(getData)">Pesan Sekarang</mat-label>
                <mat-label *ngIf="mySelect.value == ''">Pesan Sekarang</mat-label> -->
                <mat-label (click)="goToFormPage(getData)">Pesan Sekarang</mat-label>
            </div>
        </div>
    </div>
    <mat-divider style="border-top-color: gray;margin: 20px;"></mat-divider>
    <div style="margin-left: 20px;margin-right: 20px;">
        <mat-label style="color: white;font-weight: bold;font-size: x-large;">Spesifikasi</mat-label>
        <div [innerHTML]="getData.specification" style="margin-top: 20px;"></div>
    </div>
    <mat-divider style="border-top-color: gray;margin: 20px;"></mat-divider>
    <div style="margin-left: 20px;margin-right: 20px;margin-bottom: 20px;">
        <mat-label style="color: white;font-weight: bold;font-size: x-large;">Fitur</mat-label>
        <div [innerHTML]="getData.features" style="margin-top: 20px;"></div>
    </div>
    <!-- <div class="col-12" style="padding: 5px 0 10px 0;text-align: center;background-color: #181A1E;">
        <label style="font-size: 12pt;font-weight: 300;color: #A6B0B9;font-family: Roboto;">
            Atau beli melalui
        </label>
        <div style="display: flex;justify-content: center;">
            <img src="../../assets/ecom1.png" (click)="goToEcomm('https://www.tokopedia.com/voltaofficial')" height="50px" style="cursor: pointer;">
            <img src="../../assets/ecom2.png" (click)="goToEcomm('https://www.blibli.com/merchant/volta-motor-listrik-official-store/VOM-60027')" height="50px" style="cursor: pointer;">
            <img src="../../assets/ecom3.png" (click)="goToEcomm('https://shopee.co.id/voltaofficialstore')" height="50px" style="cursor: pointer;">
        </div>
    </div> -->
</div>